@font-face {
  font-family: 'Cera Pro';
  src: url('./fonts/cerapro/CeraPro-Italic.eot');
  src: local('Cera Pro Italic'), local('CeraPro-Italic'), url('./fonts/cerapro/CeraPro-Italic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/cerapro/CeraPro-Italic.woff') format('woff'), url('./fonts/cerapro/CeraPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('./fonts/cerapro/CeraPro-Regular.eot');
  src: local('Cera Pro Regular'), local('CeraPro-Regular'),
    url('./fonts/cerapro/CeraPro-Regular.eot?#iefix') format('embedded-opentype'), url('./fonts/cerapro/CeraPro-Regular.woff') format('woff'),
    url('./fonts/cerapro/CeraPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('./fonts/cerapro/CeraPro-BlackItalic.eot');
  src: local('Cera Pro Black Italic'), local('CeraPro-BlackItalic'),
    url('./fonts/cerapro/CeraPro-BlackItalic.eot?#iefix') format('embedded-opentype'), url('./fonts/cerapro/CeraPro-BlackItalic.woff') format('woff'),
    url('./fonts/cerapro/CeraPro-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('./fonts/cerapro/CeraPro-Light.eot');
  src: local('Cera Pro Light'), local('CeraPro-Light'), url('./fonts/cerapro/CeraPro-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/cerapro/CeraPro-Light.woff') format('woff'), url('./fonts/cerapro/CeraPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('./fonts/cerapro/CeraPro-BoldItalic.eot');
  src: local('Cera Pro Bold Italic'), local('CeraPro-BoldItalic'),
    url('./fonts/cerapro/CeraPro-BoldItalic.eot?#iefix') format('embedded-opentype'), url('./fonts/cerapro/CeraPro-BoldItalic.woff') format('woff'),
    url('./fonts/cerapro/CeraPro-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('./fonts/cerapro/CeraPro-Black.eot');
  src: local('Cera Pro Black'), local('CeraPro-Black'), url('./fonts/cerapro/CeraPro-Black.eot?#iefix') format('embedded-opentype'),
    url('./fonts/cerapro/CeraPro-Black.woff') format('woff'), url('./fonts/cerapro/CeraPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('./fonts/cerapro/CeraPro-Medium.eot');
  src: local('Cera Pro Medium'), local('CeraPro-Medium'), url('./fonts/cerapro/CeraPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/cerapro/CeraPro-Medium.woff') format('woff'), url('./fonts/cerapro/CeraPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('./fonts/cerapro/CeraPro-Bold.eot');
  src: local('Cera Pro Bold'), local('CeraPro-Bold'), url('./fonts/cerapro/CeraPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/cerapro/CeraPro-Bold.woff') format('woff'), url('./fonts/cerapro/CeraPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('./fonts/cerapro/CeraPro-Thin.eot');
  src: local('Cera Pro Thin'), local('CeraPro-Thin'), url('./fonts/cerapro/CeraPro-Thin.eot?#iefix') format('embedded-opentype'),
    url('./fonts/cerapro/CeraPro-Thin.woff') format('woff'), url('./fonts/cerapro/CeraPro-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('./fonts/cerapro/CeraPro-MediumItalic.eot');
  src: local('Cera Pro Medium Italic'), local('CeraPro-MediumItalic'),
    url('./fonts/cerapro/CeraPro-MediumItalic.eot?#iefix') format('embedded-opentype'), url('./fonts/cerapro/CeraPro-MediumItalic.woff') format('woff'),
    url('./fonts/cerapro/CeraPro-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('./fonts/cerapro/CeraPro-LightItalic.eot');
  src: local('Cera Pro Light Italic'), local('CeraPro-LightItalic'),
    url('./fonts/cerapro/CeraPro-LightItalic.eot?#iefix') format('embedded-opentype'), url('./fonts/cerapro/CeraPro-LightItalic.woff') format('woff'),
    url('./fonts/cerapro/CeraPro-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('./fonts/cerapro/CeraPro-ThinItalic.eot');
  src: local('Cera Pro Thin Italic'), local('CeraPro-ThinItalic'),
    url('./fonts/cerapro/CeraPro-ThinItalic.eot?#iefix') format('embedded-opentype'), url('./fonts/cerapro/CeraPro-ThinItalic.woff') format('woff'),
    url('./fonts/cerapro/CeraPro-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

* {
  font-family: 'Cera Pro', Arial, Helvetica, sans-serif;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  font-weight: normal;
}


h1 {
  font-family: 'CeraPro-Bold', Arial, Helvetica, sans-serif;
  font-size: 48px;
  line-height: 1.5;
  margin-bottom: 0;
}

h2 {
  font-family: 'CeraPro-Bold', Arial, Helvetica, sans-serif;
  font-size: 28px;
  line-height: 1.5;
  margin-bottom: 0;
}

h3 {
  font-family: 'CeraPro-Medium', Arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 1.5;
   margin-bottom: 0;
}
