@import "../node_modules/flexboxgrid/dist/flexboxgrid.min.css";
@import './normalize.css';
@import "./typography.css";

html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}
html{
	scroll-behavior: smooth;
}
html,
body {
	height: 100%;
	overflow-x: hidden;
}
body {
	font-size: 16px;
	font-weight: 300;
	font-family: 'Inter', sans-serif;
	color: #2C363F;
	line-height: 2;
}

.col-md-6 {
	margin-bottom: 5%;
	flex-basis: 100%;
	max-width: 100%;
}
.col-lg-4 {
	margin-bottom: 5%;
}
.row {
	flex-direction: column;
}
@media only screen and (min-width: 1200px) {
	.container-fluid {
		padding-right: 8rem;
		padding-left: 8rem;
	}
	.row {
		flex-direction: row;
	}
	.col-md-6 {
		flex-basis: 49%;
		max-width: 49%;
		margin-bottom: 0;
	}
	.col-md-6:not(:last-child) {
		margin-right: 2%;
	}
	.col-lg-4 {
		flex-basis: 32.33333333%;
		max-width: 32.33333333%;
		margin-bottom: 0;
	}
	.col-lg-4:not(:last-child) {
		margin-right: 1.5%;
		margin-bottom: 0%;
	}
}

h1,
h2,
h3 {
	font-weight: 600;
	color: #2c363f;
}

h1 > span {
  color: #8ac540;
  text-transform: lowercase;
  font-size: 48px;
  font-weight: 600;
}
button.nav__link {
  padding: 0.8rem 2rem;
	background-color: rgba(138, 197, 64, 0.1);
	color: #8ac540;
	border-radius: 2px;
  font-size: 0.9rem;
  font-weight: 600;
  border: none;
}
.btn-primary {
	padding: 0.8rem 2rem;
	background-color: #8ac540;
	color: #fff;
	border-radius: 2px;
	font-size: 0.9rem;
	border: none;
	box-shadow: 0px 10px 30px rgba(138, 197, 64, 0.3);
}
.btn-secondary {
	padding: 0.8rem 2rem;
	background-color: #2c363f;
	color: #8ac540;
	border-radius: 2px;
	font-size: 0.9rem;
	border: none;
	box-shadow: 0px 10px 30px rgba(138, 197, 64, 0.3);
}
.navigation {
	position: sticky;
	padding: 1.2rem 0;
	z-index: 101;
	top: 0;
	left: 0;
	right: 0;
	background-color: #fff;
}
.hamburger {
	position: relative;
}
.hamburger {
	width: 40px;
}

.hamburger:after,
.hamburger:before,
.hamburger div {
	background-color: #8ac540;
	border-radius: 3px;
	content: '';
	display: block;
	height: 4px;
	margin: 7px 0;
	transition: all .2s ease-in-out;
}
.hamburger.is-open:before {
	transform: translateY(12px) rotate(135deg);
}

.hamburger.is-open:after {
	transform: translateY(-12px) rotate(-135deg);
}

.hamburger.is-open div {
	transform: scale(0);
}
.nav {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}
.nav__menu {
	display: flex;
	flex-direction: column;
}
.nav__brand {
	display: inline-block;
	max-width: 30%;
}
.nav__brand > .logo {
	width: 100%;
}
.nav__right {
	transition: all ease-in 400ms;
	position: absolute;
	left: 0px;
	top: 5rem;
	height: 20rem;
	right: 0;
	background: #fff;
}
.nav__item {
	list-style-type: none;
}
.nav__link {
	text-decoration: none;
	font-style: normal;
	font-weight: 500;
  color: #2c363f;
  cursor: pointer;
}
.nav__item:not(:last-child) {
	padding-right: 1.5rem;
	padding-bottom: 1rem;
}
.nav__link:hover {
	color: #8ac540;
}

.hide-nav {
	display: none;
	left: -1000px;
	background: transparent;
	transition: all ease-in 400ms;
}

@media screen and (min-width: 1200px) {
	.nav__brand > .logo {
		width: 50%;
	}
	.nav__item:not(:last-child) {
		padding-bottom: 0;
	}
	.nav__right.hide-nav {
		display: flex;
		height: auto;
		top: 0;
		position: relative;
		left: 0px;
	}
	.hamburger {
		display: none;
	}
	.nav__menu {
    flex-direction: row;
    align-items: center;
	}
}

/* HERO HEADER CONTENTT */
.hero__header-text {
	padding-left: 2rem;
	padding-right: 2rem;
}
@media only screen and (min-width: 1200px) {
	.hero__header {
		min-height: 80vh;
		align-items: center;
	}
	.hero__header-text {
		padding-left: 8rem;
	}
	.hero__header-image {
		display: flex;
		justify-content: flex-end;
	}
}
.hero__header-image {
	display: none;
}
.map {
	width: 95%;
}

/* THE PROBLEM CONTENT */
@media only screen and (max-width: 1200px) {
	section {
		margin: 10rem 0;
	}
}

section {
	margin: 5rem 0;
	position: relative;
}

hr {
	border: none;
	border: 2px solid #2c363f;
	width: 40px;
	margin: 0;
	margin-bottom: 1rem;
}

h6 {
	font-weight: 600;
	font-size: 16px;
	line-height: 17px;
	color: #8ac540;
  text-transform: uppercase;
  margin: 10px 0;
}
/* OPPORTUNITY CONTENT */
@media only screen and (max-width: 1200px) {
	.opportunity > .row {
		flex-direction: column-reverse;
	}
}

/* SOLUTION CONTENT */

.solution {
	padding-top: 10rem;
	padding-bottom: 7rem;
  background: rgba(138, 197, 64, 0.1);
  margin-bottom: 0;
}

.section__video {
	background-image: url(./images/video-background.svg);
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	padding: 200px 0;

	display: flex;
	justify-content: center;
	align-items: center;
}

/* BENEFITS CONTENT*/
.benefits {
	margin-top: 7rem;
}
.benefits__item {
	background-color: #fff;
	padding: 2.5rem;
	border-radius: 2px;
	box-shadow: 0px 10px 30px rgba(138, 197, 64, 0.06);
}
.benefits__item h3 {
  margin-top: 0px;
}
/* CTA CONTENT */
footer {
	position: relative;
}
.cta {
	background-color: #8ac540;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.footer__links {
	padding-top: 5rem;
	padding-bottom: 5rem;
	background-color: #2c363f;
}

@media screen and (min-width: 1200px) {
	.cta {
		padding: 3rem 5rem;
  }
}

.footer__links ul {
	padding-left: 0;
}
.footer__links ul > li {
	list-style-type: none;
	margin-bottom: 0.5rem;
}
.footer__links h6 {
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	text-transform: uppercase;

	color: #8ac540;
	
}
.footer__links ul > li > a {
	color: #fff;
	text-decoration: none;
	cursor: pointer;
}
.footer__links ul > li > address {
	color: #fff;
	font-style: normal;
	width: 50%;
}

.arrow-left, .arrow-right{
	display: flex;
	justify-content: center;
	position: absolute;
    left: 50%;
	transform: translateX(-50%);
	z-index: 21
}
.path {
	stroke-dasharray: 10;
	stroke-dashoffset: 1000;
	animation: dash 5s linear infinite;
  }
  
  @keyframes dash {
	from {
	  stroke-dashoffset: 1000;
	}
	to {
	  stroke-dashoffset: 0;
	}
	}
	
  /** HOW **/

  .how {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background: rgba(138, 197, 64, 0.1);
  }

  .section__text h2{
    margin-top: 0;
  }